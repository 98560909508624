<template>
  <div>


    <!-- search-top -->
    <div class="search-top">
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="search-box">
            <!-- <form>
              <div class="form-group">
                <input type="search" class="form-control" aria-label="search" placeholder="Search">
                <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
              </div>
            </form> -->
          </div>
        </div>
        <div class="col-md-6 col-lg-8">
          <div class="export-right">
            <div class="export">
              <a href="javascript:" @click="createCustomerList"><img class="img-fluid" src="@/assets/img/add.svg"
                  alt="image title"> Add Customers List</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- search-top -->

    <!-- review-table -->
    <div class="review-table">
      <table class="display" id="dataTable">
        <thead>
          <tr>
            <th>No.</th>
            <th>List Name</th>
            <th>Customers</th>
            <th>Created on</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="customerList in AllCustomersLists" :key="customerList">
            <td>{{ customerList.no }}</td>
            <td>
              <router-link :to="{ name: 'CustomersWithList', params: { id: customerList.id } }">{{ customerList.title
              }}</router-link>
            </td>
            <td>{{ customerList.customers_count }}</td>
            <td>{{ customerList.created_on }}</td>
            <td>
              <a class="invites" href="javascript:" @click="editCustomerList(customerList)">Edit</a>
              <a class="invites" href="javascript:" @click="deleteCustomerList(customerList.id)">Delete</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- review-table -->

    <!-- Create-CustomerList-Modal -->
    <div class="modal fade" id="create_customers_list" tabindex="-1" aria-labelledby="createCustomersList"
      aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="editMode" class="modal-title" id="createCustomersList">Update list</h5>
            <h5 v-else class="modal-title" id="createCustomersList">Create a list</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
            </button>
          </div>
          <div class="modal-body">
            <div class="contact-form">
              <form v-on:submit.prevent="submitForm" id="addEditCustomersListForm">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="First">
                      <p class="label-txt label-active">List Name</p>
                      <input type="text" class=" input" id="create_customer_list_name"
                        v-model="create_customer_list_form.create_customer_list_name"
                        @click="removeClass('#create_customer_list_name', 'red-border')">
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button v-if="editMode" type="button" class="send-btn"
                @click="updateCustomerList(create_customer_list_form.Id)">
                Update
              </button>
              <button v-else type="button" class="send-btn" @click="addCustomerList">Create</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
// console.log(localStorage);
export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    let AllCustomersLists = ref([]);
    let editMode = ref(false);
    let create_customer_list_form = ref({
      Id: 0,
      create_customer_list_name: ''
    });

    const getCustomersList = async () => {
      const getFormData = new FormData();
      getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
      getFormData.append('location_id', localStorage.getItem('location_id'));
      await axios
        .post(axios.defaults.baseUrl + "customers/lists",
          getFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          AllCustomersLists.value = response.data.data.allCustomersLists;
          $('#disply_csv_uploaded_contact').modal('show');
            setTimeout(() => {
              $("#dataTable").dataTable({
              columnDefs: [
                { targets: [0], orderable: false, searchable: false },
                { targets: [1], orderable: true },
                { targets: [2], orderable: true },
                { targets: [3], orderable: true },
                { targets: [4], orderable: false, searchable: false }
              ]
            });
            window.showHideMainLoader(false);
            }, 500);
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          return false;
        });
    }

    const createCustomerList = async () => {
      editMode.value = false;
      create_customer_list_form.value.Id = 0;
      create_customer_list_form.value.create_customer_list_name = '';
      removeClass('.form-control', 'red-border');
      $('#create_customers_list').modal({ backdrop: 'static', keyboard: false });
    }
    const editCustomerList = async (customerList) => {
      console.log(customerList);
      editMode.value = true;
      create_customer_list_form.value.Id = customerList.id;
      create_customer_list_form.value.create_customer_list_name = customerList.title;
      removeClass('.form-control', 'red-border');
      $('#create_customers_list').modal({ backdrop: 'static', keyboard: false });
    }

    const addCustomerList = async () => {
      let validate = 1;
      console.log(validate);
      if (create_customer_list_form.value.create_customer_list_name == '') {
        validate = 0;
        addClass('#create_customer_list_name', 'red-border');
        showToast('error', 'List name is required');
        return false;
      }
      if (validate == 1) {
        window.showHideMainLoader(true);
        let addFormData = new FormData();
        addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
        addFormData.append('location_id', localStorage.getItem('location_id'));
        addFormData.append('title', create_customer_list_form.value.create_customer_list_name);
        await axios
          .post(
            axios.defaults.baseUrl + "customers/lists/add",
            addFormData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            }
          )
          .then((response) => {
            showToast(response.data.status, response.data.message);
            closeModal();
            getCustomersList();
            create_customer_list_form.value = '';
            location.assign('/customers/'+response.data.data.list_id+'/new');
            window.showHideMainLoader(false);
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            showToast(error.response.data.status, error.response.data.message);
            return false;
          });
      }
    }

    const updateCustomerList = async (Id) => {
      let validate = 1;
      console.log(validate);
      if (create_customer_list_form.value.create_customer_list_name == '') {
        validate = 0;
        addClass('#create_customer_list_name', 'red-border');
        showToast('error', 'List name is required');
        return false;
      }
      if (validate == 1) {
        window.showHideMainLoader(true);
        let addFormData = new FormData();
        addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
        addFormData.append('location_id', localStorage.getItem('location_id'));
        addFormData.append('title', create_customer_list_form.value.create_customer_list_name);
        await axios
          .post(
            axios.defaults.baseUrl + "customers/lists/update/" + Id,
            addFormData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            }
          )
          .then((response) => {
            showToast(response.data.status, response.data.message);
            closeModal();
            setTimeout(() => {
              
              getCustomersList();
              // datatableInit();
            });
            create_customer_list_form.value = '';
            location.reload(); //-----remove later
            window.showHideMainLoader(false);
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            showToast(error.response.data.status, error.response.data.message);
            return false;
          });
      }
    }
    const deleteCustomerList = async (Id) => {
      window.showHideMainLoader(true);
      await axios
        .delete(axios.defaults.baseUrl + "customers/lists/delete/" + Id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          showToast(response.data.status, response.data.message);
          getCustomersList();
          location.reload(); //-----remove later
          window.showHideMainLoader(false);
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          return false;
        })
    }

    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }
    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }
    // Open Modal
    const openModal = () => {
    };

    // Close Modal
    const closeModal = () => {
      // $("#create_contact").modal("hide");
      // $("#upload_contacts").modal("hide");
      $("#create_customers_list").modal("hide");
      // $("#disply_csv_uploaded_contact").modal("hide");
    };
    /*const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          { targets: [0], orderable: false },
          { targets: [1], orderable: false },
          { targets: [2], orderable: false },
          { targets: [3], orderable: false },
          { targets: [4], orderable: false }
        ]
      });
      $(".display").addClass("nowrap").dataTable();

    }*/
    onMounted(() => {
      getCustomersList();
      // datatableInit();
      // console.log($('#listId').val());
      // getCustomers();
      // window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      AllCustomersLists,
      create_customer_list_form,
      createCustomerList,
      addCustomerList,
      editCustomerList,
      updateCustomerList,
      deleteCustomerList,
      addClass,
      removeClass,
      showToast,
      editMode
    };
  },
};
</script>