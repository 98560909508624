<template>
  <!-- setting-note -->
  <div class="setting-note">
    <div class="row">
      <div class="col-md-8 col-xl-8 mb-4">
        <div class="review-goal">
          <h3>My Profile</h3>
          <div class="contact-form">
            <form>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="create_contact_first_name">
                    <p class="label-txt label-active">First Name</p>
                    <input type="text" class=" input" id="create_contact_first_name" v-model="profile_first_name"
                      readonly>
                  </label>
                </div>
                <div class="form-group col-md-6">
                  <label for="create_contact_last_name">
                    <p class="label-txt label-active">Last Name</p>
                    <input type="text" class=" input" id="create_contact_last_name" v-model="profile_last_name" readonly>
                  </label>
                </div>
                <div class="form-group col-md-6">
                  <label for="create_contact_email">
                    <p class="label-txt label-active">Email Address</p>
                    <input type="email" class=" input" id="create_contact_email" v-model="profile_email" readonly>
                  </label>
                </div>
                <div class="form-group col-md-6">
                  <label for="create_contact_phone">
                    <p class="label-txt label-active">Phone no</p>
                    <input type="text" class=" input" id="create_contact_phone" v-model="profile_phone" readonly>
                  </label>
                </div>
                <div class="form-group col-md-12">
                  <label for="create_contact_address">
                    <p class="label-txt label-active">Full Address</p>
                    <textarea class="input" id="create_contact_address" rows="2" v-model="profile_address"
                      readonly></textarea>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xl-4 mb-4">
        <div class="review-goal">
          <h3>Phone number for SMS</h3>
          <div class="contact-form">
            <form>
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="twilio_phone_no">
                    <p class="label-txt label-active label-active">Phone no</p>
                    <input type="text" class=" input" id="twilio_phone_no" v-model="twilio_number" readonly />
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <button v-if="phone_no_request == 0" type="button" class="btn btn-primary"
                    @click="RequestPhoneNo">Request
                    Phone No.</button>
                  <button v-else-if="phone_no_request == 2" type="button" class="btn btn-primary">Requested</button>
                </div>
              </div>
            </form>
          </div>
          <h3>Email Address for CRM</h3>
          <div class="contact-form">
            <form>
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="twilio_phone_no">
                    <p class="label-txt label-active label-active">Email Address</p>
                    <input type="text" class=" input" id="crm_email" v-model="crm_email" />
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <button type="button" class="btn btn-primary" @click="SubmitCrmEmail">Update</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- setting-note -->
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    let profile_first_name = ref('');
    let profile_last_name = ref('');
    let profile_email = ref('');
    let profile_phone = ref('');
    let profile_address = ref('');
    let phone_no_request = ref('');
    let crm_email = ref('');
    let twilio_number = ref('(XXX) XXX-XXXX');

    const getUserProfile = async () => {
      let profileFormData = new FormData();
      profileFormData.append('dealer_id', localStorage.getItem('dealer_id'));
      profileFormData.append('location_id', localStorage.getItem('location_id'));
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "profile-settings",
          profileFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          let response_data = response.data.data;
          profile_first_name.value = response_data.first_name;
          profile_last_name.value = response_data.last_name;
          profile_email.value = response_data.email;
          profile_phone.value = response_data.phone;
          profile_address.value = response_data.address;
          if (response_data.phone_no_request == 1) {
            twilio_number.value = response_data.twilio_phone;
          }
          phone_no_request.value = response_data.phone_no_request;
          crm_email.value = response_data.crm_email;
        });
    }
    const RequestPhoneNo = async () => {
      window.showHideMainLoader(true);
      await axios
        .get(
          axios.defaults.baseUrl + "request-twilio-phone-no",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          phone_no_request.value = 2;
          window.showHideMainLoader(false);
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          return false;
        });
    }
    const SubmitCrmEmail = async () => {
      window.showHideMainLoader(true);
      let updateFormData = new FormData();
      updateFormData.append('crm_email', crm_email.value);
      await axios
        .post(
          axios.defaults.baseUrl + "update-dealer-crm-email",
          updateFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          showToast(response.data.status, response.data.message);
          window.showHideMainLoader(false);
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          return false;
        });
    }
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }
    // Open Modal
    const openModal = () => {
      // $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      // $("#featureNotAvailable").modal("hide");
    };

    onMounted(() => {
      getUserProfile();
      window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      profile_first_name,
      profile_last_name,
      profile_email,
      profile_phone,
      profile_address,
      phone_no_request,
      twilio_number,
      crm_email,
      RequestPhoneNo,
      SubmitCrmEmail,
      removeClass,
      addClass,
      showToast,
    };
  },
};
</script>