<template>
<div>

    <!-- Email campaign -->
    <div class="email-step1">
        <!-- email-next -->
        <div class="email-next">
            <div class="list-btns">
                <ul>
                    <li><a class="active step1" href="javascript:;">Details</a></li>
                    <li><a class="step2" href="javascript:;">Customize Template</a></li>
                    <li><a class="step3" href="javascript:;">Review</a></li>
                    <li><a class="step4" href="javascript:;">Recipients</a></li>
                    <span class="bar"></span>
                </ul>
            </div>
        </div>
        <!-- email-next -->

        <div class="email-campaign">
            <!-- step1-area -->
            <div class="step1-area">
                <div class="email-deatils">
                    <h3>Campaign Name</h3>
                    <form action="">
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="campaign_name" aria-label="Campaign Name" placeholder="Campaign Name" v-model="text_campaign_form.campaign_name" />
                                </div>
                            </div>
                            <!--
                <div class=" col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" aria-label="Enater Campaign Name" id="inputAddress24"
                      placeholder="Enater Campaign Name" v-model="text_campaign_form.template_textarea" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group ">
                    <input type="text" class="form-control" aria-label="Form Name" id="inputAddress24"
                      placeholder="Form Name" v-model="text_campaign_form.template_textarea" />
                  </div>
                </div>
-->
                        </div>

                        <!-- email-template -->
                        <div class="email-template">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="lefts">
                                        <h3>Choose Template</h3>
                                    </div>
                                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a class="nav-link" id="v-pills-touch-tab" data-toggle="pill" :href="'#v-pills-touch' + Template.id" role="tab" aria-controls="v-pills-touch" aria-selected="true" v-for="Template in AllTemplates" :key="Template" @click="putTemplateValue(Template.id, Template.template, Template.image)">{{ Template.title }}</a>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="v-pills-touch-tab">
                                            <div class="preview" style="margin: 15px; min-height: 200px;">
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" :id="'v-pills-touch' + Template.id" role="tabpanel" aria-labelledby="v-pills-touch-tab" v-for="Template in AllTemplates" :key="Template">
                                            <div class="email-boxs">
                                                <div class="campaign-template-flex-box">
                                                    <div class="campaign-template-image-box" v-if="Template.image">
                                                        <h3>Image</h3>
                                                        <img :src="base_url + 'template/' + Template.image" />
                                                    </div>
                                                    <div style="width: 100%">
                                                        <h3>Message</h3>
                                                        <div class="preview" style="min-height: 200px; margin: 15px; padding: 25px; line-height: 35px;" v-html="Template.template"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- email-template -->
                    </form>
                </div>
            </div>
            <!-- step1-area -->

        </div>
        <!-- Email campaign -->
        <!-- next-btns -->
        <div class="next-btns modal-footer email-footer">

            <a href="javascript:;" @click="gotoStep2('next', 'selected')" class="add-btn">Select Template</a>
            <!--<a href="javascript:;" class="rem-btn">Delete Template</a>-->
            <a class="step2" @click="gotoStep2('next')" href="javascript:;">Create New Template</a>
        </div>
        <!-- next-btns -->

    </div>
    <!-- email-setp1 end-->

    <!-- email-step3 -->
    <div class="email-step2">

        <!-- email-next -->
        <div class="email-next">
            <div class="back">
                <a class="step1" href="javascript:;" @click="gotoStep1('prev')"><img class="img-fluid" src="@/assets/img/arrow-left.svg" alt="image ttile"> Back</a>
            </div>
            <div class="list-btns">
                <ul>
                    <li><a class="step1" href="javascript:;">Details</a></li>
                    <li><a class="active step2" href="javascript:;">Customize Template</a></li>
                    <li><a class="step3" href="javascript:;">Review</a></li>
                    <li><a class="step4" href="javascript:;">Recipients</a></li>
                    <span class="bar"></span>
                </ul>
            </div>

        </div>
        <!-- email-next -->

        <div class="email-campaign">

            <!-- step1-area -->
            <div class="step1-area">
                <div class="email-deatils">
                    <form action="">
                        <div class="form-group">
                            <label for="Customize">Customize Template</label>
                            <textarea id="template_textarea" class="form-control" v-model="text_campaign_form.campaign_template" placeholder="Template Message..." @keyup="countChar($event)" @click="removeClass('#template_textarea', 'red-border')"></textarea>
                            <div id="charNum2"></div>
                            <!-- <TinyMceEditor v-model="text_campaign_form.campaign_template"
                  api-key="b0a86stci3bma8udzy2emmq8r6csqg497zdv3pg33p1ifmre" :init="{
                    // inital_
                    menubar: false,
                    branding: false,
                    extended_valid_elements: 'span[class|align|style]',
                    forced_root_block_attrs: { 'class': 'root_block_p' },
                    element_format: 'html',
                    relative_urls: false,
                    remove_script_host: false,
                    height: 200,
                    browser_spellcheck: true,
                    verify_html: false,
                    // images_upload_handler: tinyMceUploader,
                    plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code insertdatetime table paste emoticons',
                    toolbar: 'fontselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image | forecolor backcolor | emoticons | code'
                  }" /> -->
                        </div>
                        <div class="text-template variable">
                            <p>Variable :</p>
                            <ul>
                                <li><a href="javascript:;" @click="variable_inupt($event)">%CustomerName%</a></li>
                                <!-- <li><a href="javascript:;" @click="variable_inupt($event)">%Last-Name%</a></li>
                                <li><a href="javascript:;" @click="variable_inupt($event)">%companyname%</a></li>
                                <li><a href="javascript:;" @click="variable_inupt($event)">%companyphone%</a></li>
                                <li><a href="javascript:;" @click="variable_inupt($event)">%companyemail%</a></li>
                                <li><a href="javascript:;" @click="variable_inupt($event)">%companyaddress%</a></li> -->
                            </ul>
                        </div>
                    </form>
                </div>

            </div>
            <!-- step1-area -->

        </div>
        <!-- Email campaign -->
        <!-- next-btns -->
        <div class="next-btns">
            <a class="step3" @click="gotoStep3('next')" href="javascript:;">next</a>
        </div>
        <!-- next-btns -->

    </div>
    <!-- email-setp3 end-->

    <!-- email-step4 -->
    <div class="email-step3">
        <!-- email-next -->
        <div class="email-next">
            <div class="back">
                <a class="step2" href="javascript:;" @click="gotoStep2('prev')"><img class="img-fluid" src="@/assets/img/arrow-left.svg" alt="image ttile"> Back</a>
            </div>
            <div class="list-btns">
                <ul>
                    <li><a class="step1" href="javascript:;">Details</a></li>
                    <li><a class="step2" href="javascript:;">Customize Template</a></li>
                    <li><a class="active step3" href="javascript:;">Review</a></li>
                    <li><a class="step4" href="javascript:;">Recipients</a></li>
                    <span class="bar"></span>
                </ul>
            </div>

        </div>
        <!-- email-next -->

        <div class="email-campaign">
            <!-- step1-area -->
            <div class="step1-area">
                <div class="email-deatils">
                    <div class="campaign-template-flex-box">
                        <div class="campaign-template-image-box" v-if="text_campaign_form.campaign_template_image">
                            <h3>Image</h3>
                            <img :src="base_url + 'template/' + text_campaign_form.campaign_template_image" />
                        </div>
                        <div style="width: 100%">
                            <h3>Message</h3>
                            <div class="preview" v-html="text_campaign_form.campaign_template" style="min-height: 200px; margin: 15px; padding: 25px; line-height: 35px;">
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <!-- step1-area -->

        </div>
        <!-- Email campaign -->
        <!-- next-btns -->
        <div class="next-btns">
            <a class="step4" @click="gotoStep4('next')" href="javascript:;">next</a>
            <a class="float-left" @click="openTestTextModal" href="javascript:;">Send Test</a>
        </div>
        <!-- next-btns -->

    </div>
    <!-- email-setp4 end-->

    <!-- email-step5 -->
    <div class="email-step4">

        <!-- email-next -->
        <div class="email-next">
            <div class="back">
                <a class="step3" href="javascript:;" @click="gotoStep3('prev')"><img class="img-fluid" src="@/assets/img/arrow-left.svg" alt="image ttile"> Back</a>
            </div>
            <div class="list-btns">
                <ul>
                    <li><a class="step1" href="javascript:;">Details</a></li>
                    <li><a class="step2" href="javascript:;">Customize Template</a></li>
                    <li><a class="step3" href="javascript:;">Review</a></li>
                    <li><a class="active step4" href="javascript:;">Recipients</a></li>
                    <span class="bar"></span>
                </ul>
            </div>

        </div>
        <!-- email-next -->
        <div class="email-campaign">

            <!-- step1-area -->
            <div class="step1-area">
                <div class="email-deatils">
                    <h3>Consent</h3>
                    <form action="">
                        <div class="form-row">
                            <div class="form-group col-sm-12 col-md-12 col-lg-12">
                                <h5>
                                    <span>
                                        <!-- Rounded switch -->
                                        <label class="switch">
                                            <input type="checkbox" class="switch-checkbox" id="consentBtn" />
                                            <span class="slider round"></span>
                                        </label>
                                    </span>
                                    These clients have consented to receive marketing communications.
                                </h5>
                                <h5 class="text-danger consent-error-msg" style="display:none;">Must accept consent before continuing.</h5>
                            </div>
                        </div>
                    </form>
                    <h3>Select Recipients</h3>
                    <form action="">
                        <div class="form-row">
                            <div class="form-group col-sm-6 col-md-6 col-lg-4">
                                <label class="con-company email-contacts-type list-btn" @click="showRecipients('list')">Contact lists
                                    <input type="radio" name="recipient_type" value="list" v-model="text_campaign_form.campaign_recipient_type">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="form-group col-sm-6 col-md-6 col-lg-4">
                                <label class="con-company email-contacts-type Indivi-btn" @click="showRecipients('individual')">Individual contacts
                                    <input type="radio" name="recipient_type" value="individual" v-model="text_campaign_form.campaign_recipient_type">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                        <!-- list-tab -->
                        <div class="list-tab">
                            <div class="selected-item-list">
                                <span v-for="(Selected, keyIndex) in SelectedCustomersListList" v-bind:key="Selected.id" v-bind:value="Selected.id" :class="'selected-item selected-customer-'+Selected.id">
                                    <button type="button" class="selected-item-button" data-type="list" :data-id="Selected.id" :data-index="keyIndex" title="Remove item" aria-label="Remove item">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <span class="selected-item-text">{{ Selected.title }}</span>
                                </span>
                            </div>
                            <div class="selected-item-list add-title" style="position: relative;">
                                <input v-model="text_campaign_form.campaign_search_customer_list" type="text" class="form-control" placeholder="Search Lists..." id="appointment-customers-input" @click="openCustomerListBox('list')" @keyup="searchCustomerListBox('list')" />
                                <input type="hidden" v-model="text_campaign_form.campaign_customers_list" />
                                <div class="appointment-customers-list-box">
                                    <ul class="appointment-customers-list">
                                        <li class="appointment-customers-list-item" v-for="listOption in AllCustomersLists" v-bind:key="listOption.id" v-bind:value="listOption.id">
                                            <a :class="{'appointment-customers-item':true, 'selected-item-bg':text_campaign_form.campaign_customers_list.includes(listOption.id)}" href="javascript:;" :data-id="listOption.id" :data-name="listOption.title" @click="customerSelectId(listOption.title, listOption.id,'list')">{{ listOption.title }}</a>
                                        </li>
                                    </ul>
                                    <span class="appointment-customers-searching" style="display: none;">Searching...</span>
                                    <span class="appointment-customers-notfound" style="display: none;">No Customer found.</span>
                                </div>
                            </div>
                        </div>
                        <!-- list-tab -->

                        <!-- Individual-tab -->
                        <div class="Individual-tab">
                            <div class="selected-item-list">
                                <span v-for="(Selected, keyIndex) in SelectedCustomersList" v-bind:key="Selected.id" v-bind:value="Selected.id" :class="'selected-item selected-customer-'+Selected.id">
                                    <button type="button" class="selected-item-button" data-type="customer" :data-id="Selected.id" :data-index="keyIndex" title="Remove item" aria-label="Remove item">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <span class="selected-item-text">{{ Selected.name }}</span>
                                </span>
                            </div>
                            <div class="selected-item-list add-title" style="position: relative;">
                                <input v-model="text_campaign_form.campaign_search_customer_name" type="text" class="form-control" placeholder="Search Customers..." id="appointment-customers-input" @click="openCustomerListBox('customer')" @keyup="searchCustomerListBox('customer')" />
                                <input type="hidden" v-model="text_campaign_form.campaign_customers" />
                                <div class="appointment-customers-list-box">
                                    <ul class="appointment-customers-list">
                                        <li class="appointment-customers-list-item" v-for="Customer in AllCustomers" v-bind:key="Customer.id" v-bind:value="Customer.id">
                                            <a :class="{'appointment-customers-item':true, 'selected-item-bg':text_campaign_form.campaign_customers.includes(Customer.id)}" href="javascript:;" :data-id="Customer.id" :data-name="Customer.first_name+' '+Customer.last_name" @click="customerSelectId(Customer.first_name+' '+Customer.last_name, Customer.id,'customer')">{{ Customer.first_name+' '+Customer.last_name }}</a>
                                        </li>
                                    </ul>
                                    <span class="appointment-customers-searching" style="display: none;">Searching...</span>
                                    <span class="appointment-customers-notfound" style="display: none;">No Customer found.</span>
                                </div>
                            </div>
                        </div>
                        <!-- Individual-tab -->

                    </form>
                </div>
            </div>
            <!-- step1-area -->
            <!-- step1-area -->
            <div class="step1-area">
                <div class="email-deatils">
                    <h3>Sending Options</h3>
                    <form action="">

                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="con-company email-send-type send-tab" aria-label="Send now">Send now
                                    <input type="radio" name="radio" value="now" v-model="text_campaign_form.campaign_send_option" @click="sendOption('now')" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="form-group col-md-6 col-lg-4">
                                <label class="con-company email-send-type sche-tab" aria-label="Later">Schedule for Later
                                    <input type="radio" name="radio" value="later" v-model="text_campaign_form.campaign_send_option" @click="sendOption('later')" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="form-group col-md-6 col-lg-5">
                                <label class="con-company email-send-type zoon-tab" aria-label="time zones">Schedule based on time zones
                                    <input type="radio" name="radio" value="timezone" v-model="text_campaign_form.campaign_send_option" @click="sendOption('timezone')" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>

                        <!-- send-now -->
                        <div class="send-now">
                            <div class="send-info">
                                <p>Your text will be sent immediately.</p>
                            </div>

                            <div class="send-btna">
                                <a href="javascript:;" @click="handleSubmitCampaign('now')">Send Now</a>
                            </div>
                        </div>
                        <!-- send-now -->

                        <!-- sche-later -->
                        <div class="sche-later">
                            <div class="send-info">
                                <p>Schedule a future time and date to publish your texts.</p>
                                <h3>When do you want to send it out?</h3>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-4 col-lg-4">
                                    <input type="date" class="form-control" placeholder="Schedule based on time zones" v-model="text_campaign_form.campaign_send_date">

                                </div>
                                <div class="form-group col-md-4 col-lg-4">
                                    <input type="time" class="form-control" v-model="text_campaign_form.campaign_send_time">
                                </div>
                                <!-- timezoon -->
                                <div class="timezoon">
                                    <div class="form-group col-md-4 col-lg-4">
                                        <select class="form-control" v-model="text_campaign_form.campaign_send_timezone">
                                            <option value="" selected>Select Timezone</option>
                                            <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                                            <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                            <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                                            <option value="US/Alaska">(GMT-09:00) Alaska</option>
                                            <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                            <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                                            <option value="US/Arizona">(GMT-07:00) Arizona</option>
                                            <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                            <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                                            <option value="America/Managua">(GMT-06:00) Central America</option>
                                            <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                                            <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                            <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                            <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                            <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                                            <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                                            <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                                            <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                                            <option value="America/Manaus">(GMT-04:00) Manaus</option>
                                            <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                            <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                                            <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                            <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                                            <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                            <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                            <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                            <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                            <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                            <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                                            <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon,
                                                London
                                            </option>
                                            <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna
                                            </option>
                                            <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague
                                            </option>
                                            <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                            <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                                            <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                                            <option value="Asia/Amman">(GMT+02:00) Amman</option>
                                            <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                                            <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                            <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                            <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                                            <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius
                                            </option>
                                            <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                            <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                            <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                                            <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                                            <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                            <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                                            <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                                            <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                            <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                                            <option value="Asia/Baku">(GMT+04:00) Baku</option>
                                            <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                            <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                            <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                                            <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                                            <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                            <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                                            <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                            <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                                            <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                            <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                            <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                            <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                            <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                            <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                                            <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                            <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                            <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                                            <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                            <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                            <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                            <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                            <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                            <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                            <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                                            <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                            <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                                            <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                            <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                                            <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                            <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                            <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- timezoon -->
                            </div>

                            <div class="send-btna">
                                <a href="javascript:;" @click="handleSubmitCampaign('later')">Schedule Now</a>
                            </div>
                        </div>
                        <!-- sche-later -->

                    </form>
                </div>
            </div>
            <!-- step1-area -->

        </div>
        <!-- Email campaign -->
    </div>
    <!-- email-setp5 end -->
</div>
<!-- test-text-Modal -->
<div class="modal fade" id="sendTestTextModal" tabindex="-1" aria-labelledby="sendTestTextModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xs">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="sendTestTextModalLabel">
                    Send Test SMS
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title" />
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <vue-mask class="form-control" mask="(000)000-0000" :options="options" id="testPhoneNo" placeholder="(xxx)xxx-xxxx" v-model="testPhoneNo" @click="removeClass('#Phone', 'red-border')"></vue-mask>
                        </div>
                        <!-- create-btms -->

                        <div class="create-btn">
                            <a href="#" data-dismiss="modal" aria-label="Close">Close</a>
                            <a class="create" href="javascript:;" @click="sendTestText">Send</a>
                        </div>
                        <!-- create-btms -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.campaign-template-flex-box {
    display: flex;
}

.campaign-template-flex-box h3 {
    margin-left: 10px;
}

.campaign-template-image-box {
    width: 200px;
    padding: 5px;
    /*text-align: center;*/
}

.campaign-template-image-box img {
    width: 100%;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #6046fe;
}

input:focus+.slider {
    box-shadow: 0 0 1px #6046fe;
}

input:checked+.slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
h5.text-danger {
    font-size: 14px;
    margin-top: 10px;
}
#sendTestTextModal .modal-xs {
    width: 280px;
}
</style>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";
import vueMask from 'vue-jquery-mask';
// import TinyMceEditor from '@tinymce/tinymce-vue';
// import { useRoute } from "vue-router";
import routes from "./../../routes";
import "select2";
import 'select2/dist/css/select2.css';
export default {
    components: {
        vueMask,
        // TinyMceEditor
    },
    setup() {
        let base_url = "https://api.getstreamly.com/uploads/";
        let AllTemplates = ref([]);
        let current_Template = 0;
        let testPhoneNo = ref('');
        // const route = useRoute();
        let company_detail = ref({
            company_name: '',
            company_email: '',
            company_phone: '',
            company_address: '',
        });
        let text_campaign_form = ref({
            campaign_name: '',
            campaign_template_id: 0,
            campaign_template: '',
            campaign_template_image: '',
            campaign_search_customer_list: '',
            campaign_search_customer_name: '',
            campaign_customers: [],
            campaign_customers_list: [],
            campaign_recipient_type: '',
            campaign_send_date: '',
            campaign_send_option: '',
            campaign_send_time: '',
            campaign_send_timezone: '',
        });
        let AllCustomersLists = ref([]);
        let AllCustomers = ref([]);
        let SelectedCustomersList = ref([]);
        let SelectedCustomersListList = ref([]);
        const putTemplateValue = async (Id = 0, selected_template = '', template_image = '') => {
            current_Template = Id;
            text_campaign_form.value.campaign_template_image = template_image;
            text_campaign_form.value.campaign_template = selected_template;
            text_campaign_form.value.campaign_template_id = Id;
        }
        const gotoStep1 = async (param = null) => {
            console.log(param);
            $(".email-step1").show();
            $(".email-step2").hide();
            $(".email-step3").hide();
            $(".email-step4").hide();
            $(".email-step5").hide();
        }
        const gotoStep2 = async (param = null, selected = null) => {
            if (text_campaign_form.value.campaign_name == '') {
                showToast('error', 'Campaign name is required!')
                return false;
            }
            // console.log(param);
            $(".email-step1").hide();
            $(".email-step2").show();
            $(".email-step3").hide();
            $(".email-step4").hide();
            $(".email-step5").hide();
            if (param == 'next') {
                if (selected == null) {
                    current_Template = 0;
                    text_campaign_form.value.campaign_template = '';
                }
            }
        }
        const gotoStep3 = async (param = null) => {
            console.log(param);
            $(".email-step1").hide();
            $(".email-step2").hide();
            $(".email-step3").show();
            $(".email-step4").hide();
            $(".email-step5").hide();
        }
        const gotoStep4 = async (param = null) => {
            console.log(param);
            $(".email-step1").hide();
            $(".email-step2").hide();
            $(".email-step3").hide();
            $(".email-step4").show();
            $(".email-step5").hide();
        }

        const showRecipients = async (param = null) => {
            // console.log(param);
            $('.email-contacts-type').removeClass('currnt');
            if (param == 'list') {
                $(".list-tab").show();
                $(".Individual-tab").hide();
                $('.list-btn').addClass('currnt');
            } else {
                $(".list-tab").hide();
                $(".Individual-tab").show();
                $('.Indivi-btn').addClass('currnt');
            }
        }
        const sendOption = async (param = null) => {
            // console.log(param);
            $('.email-send-type').removeClass('currnt');
            if (param == 'now') {
                $(".send-now").show();
                $(".sche-later").hide();
                $(".timezoon").hide();
                $('.send-tab').addClass('currnt');
            } else if (param == 'later') {
                $(".send-now").hide();
                $(".sche-later").show();
                $(".timezoon").hide();
                $('.sche-tab').addClass('currnt');
            } else {
                $(".sche-later").show();
                $(".timezoon").show();
                $('.zoon-tab').addClass('currnt');
            }
        }
        const getCompanyDetails = async () => {
            await axios
                .get(
                    axios.defaults.baseUrl + "profile", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    company_detail.value.company_name = response.data.company_name;
                    company_detail.value.company_email = response.data.company_email;
                    company_detail.value.company_phone = response.data.company_phone;
                    company_detail.value.company_address = response.data.company_address;
                    // AllTemplates.value = response.data.data.allTemplates;
                    // datatableInit();
                })
                .catch((error) => {
                    console.log(error);
                    window.showHideMainLoader(false);
                    // showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }

        const getTextTemplates = async () => {
            const getFormData = new FormData();
            // getFormData.append('list_id', list_id);
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(axios.defaults.baseUrl + "templates/text",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllTemplates.value = response.data.data.allTemplates;
                    // datatableInit();
                })
        }
        const getCustomersList = async () => {
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(axios.defaults.baseUrl + "customers/lists",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllCustomersLists.value = response.data.data.allCustomersLists;
                    // datatableInit();
                })
        }
        const getCustomers = async (list_id = null) => {
            const getFormData = new FormData();
            getFormData.append('list_id', list_id);
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(axios.defaults.baseUrl + "customers",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllCustomers.value = response.data.data.allCustomers;
                    // datatableInit();
                })
        }
        const searchCustomerListBox = async (type = null) => {
            let search_param = '';
            let currentUrl = '';
            if (type == 'customer') {
                currentUrl = "customers/appointment";
                search_param = text_campaign_form.value.campaign_search_customer_name;
            } else {
                currentUrl = "customers/lists";
                search_param = text_campaign_form.value.campaign_search_customer_list;
            }

            $('.appointment-customers-notfound').hide();
            $('.appointment-customers-list').hide();
            $('.appointment-customers-searching').show();
            const getFormData = new FormData();

            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            if (search_param.length >= 2) {
                getFormData.append('search_param', search_param);
            }
            // console.log(currentUrl);
            await axios
                .post(axios.defaults.baseUrl + currentUrl,
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    if (type == 'customer') {
                        AllCustomers.value = response.data.data.allCustomers;
                    } else {
                        AllCustomersLists.value = response.data.data.allCustomersLists;
                    }

                    // console.log(response.data.data.allCustomers);
                    $('.appointment-customers-searching').hide();
                    if (!response.data.data.no_customers) {
                        $('.appointment-customers-list').show();
                    } else {
                        $('.appointment-customers-notfound').show();
                    }
                })
        }
        const openCustomerListBox = async () => {
            if (!$('.appointment-customers-list-box').is(':visible')) {
                $('.appointment-customers-list-box').show();
            } else {
                $('.appointment-customers-list-box').hide();
            }
        }
        const handleSubmitCampaign = async (param = null) => {
            console.log(param);
            $('.consent-error-msg').hide();
            // console.log(text_campaign_form.value.campaign_customers_list);
            if(!$('#consentBtn').is(':checked')){
                window.toast.fire({
                    icon: 'error',
                    title: 'Clients must consent to receive marketing messages.',
                });
                $('.consent-error-msg').show();
                return false;
            }
            if (text_campaign_form.value.campaign_recipient_type == 'list' && text_campaign_form.value.campaign_customers_list == '') {
                window.toast.fire({
                    icon: 'error',
                    title: 'Please select atlease 1 list!',
                });
                return false;
            } else if (text_campaign_form.value.campaign_recipient_type == 'individual' && text_campaign_form.value.campaign_customers == '') {
                window.toast.fire({
                    icon: 'error',
                    title: 'Please select atlease 1 customer!',
                });
                return false;
            }
            const getFormData = new FormData();
            // getFormData.append('list_id', list_id);
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            getFormData.append('title', text_campaign_form.value.campaign_name);
            getFormData.append('campaign_template_id', text_campaign_form.value.campaign_template_id);
            getFormData.append('campaign_template', text_campaign_form.value.campaign_template);
            getFormData.append('campaign_recipient_type', text_campaign_form.value.campaign_recipient_type);
            getFormData.append('campaign_customers_list', text_campaign_form.value.campaign_customers_list);
            getFormData.append('campaign_customers', text_campaign_form.value.campaign_customers);
            getFormData.append('campaign_send_option', text_campaign_form.value.campaign_send_option);
            getFormData.append('campaign_send_date', text_campaign_form.value.campaign_send_date);
            getFormData.append('campaign_send_time', text_campaign_form.value.campaign_send_time);
            getFormData.append('campaign_send_timezone', text_campaign_form.value.campaign_send_timezone);
            await axios
                .post(axios.defaults.baseUrl + "campaigns/text/add",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                    // console.log(routes);
                    // AllCustomers.value = response.data.data.allCustomers;
                    // datatableInit();
                    routes.push("/text-campaign");
                })
        }
        const variable_inupt = (event) => {
            // console.log(event.target.innerHTML);
            let thisVal = event.target.innerHTML;
            // let bookmark = TinyMceEditor.activeEditor.selection.getBookmark();
            // let description = TinyMceEditor.getInstanceById('editortiny').getContent();
            // let description = TinyMceEditor.activeEditor.getContent();
            if (thisVal == '%companyname%') {
                thisVal = company_detail.value.company_name;
            }
            if (thisVal == '%companyemail%') {
                thisVal = company_detail.value.company_email;
            }
            if (thisVal == '%companyphone%') {
                thisVal = company_detail.value.company_phone;
            }
            if (thisVal == '%companyaddress%') {
                thisVal = company_detail.value.company_address;
            }
            // console.log(thisVal);

            let description = text_campaign_form.value.campaign_template + thisVal;
            // console.log(description);
            text_campaign_form.value.campaign_template = description;
            setTimeout(function () {
                countChar();
            }, 300);

        }
        const countChar = (event = null) => {
            console.log(event);
            let maxLength = 320;
            let smsLength = 160;
            // let messageLength = event.target.value.length;
            let template_textarea = $('#template_textarea').val();
            // console.log(template_textarea);
            let messageLength = template_textarea.length;
            if (messageLength > 160) {
                smsLength = 320;
            }
            if (messageLength >= maxLength) {
                messageLength = template_textarea.substring(0, maxLength);
            } else {
                $('#charNum').text(messageLength + '/' + smsLength);
            }
            // $('#charNum').text(messageLength+'/'+maxCount);
        }
        const openTestTextModal = () => {
            $('#sendTestTextModal').modal('show');
        }
        const sendTestText = async () => {
            removeClass('#testPhoneNo', 'red-border');
            if(testPhoneNo.value == ''){
                addClass('#testPhoneNo', 'red-border');
                return false;
            }
            let sendTestTextFormData = new FormData();
            sendTestTextFormData.append('phone_no', testPhoneNo.value);
            sendTestTextFormData.append('message', text_campaign_form.value.campaign_template);
            sendTestTextFormData.append('template_id', text_campaign_form.value.campaign_template_id);
            window.showHideMainLoader(true);
            await axios
                .post(axios.defaults.baseUrl + "sendTestTextSMS",
                    sendTestTextFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    window.showHideMainLoader(false);
                    console.log(response);
                    $('#sendTestTextModal').modal('hide');
                    testPhoneNo.value = '';
                    showToast("success","Text message sent!");
                })
                .catch((error) => {
                    console.log(error);
                    window.showHideMainLoader(false);
                    // showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }
        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        // Open Modal
        const openModal = () => {
            $("#featureNotAvailable").modal("show");
        };
        // Close Modal
        const closeModal = () => {
            $("#featureNotAvailable").modal("hide");
        };
        const customerSelectId = async (selected_name = null, selected_id = null, type = null) => {
            let arrIndex = -1;
            if (type == 'customer') {
                arrIndex = text_campaign_form.value.campaign_customers.findIndex(thisItem => thisItem == selected_id);
            } else {
                arrIndex = text_campaign_form.value.campaign_customers_list.findIndex(thisItem => thisItem == selected_id);
            }
            if (arrIndex < 0) {
                let newArray = [];
                newArray['id'] = selected_id;
                if (type == 'customer') {
                    newArray['name'] = selected_name;
                    SelectedCustomersList.value.push(newArray);
                    text_campaign_form.value.campaign_customers.push(selected_id);
                } else {
                    newArray['title'] = selected_name;
                    SelectedCustomersListList.value.push(newArray);
                    text_campaign_form.value.campaign_customers_list.push(selected_id);
                }
            }
            text_campaign_form.value.campaign_search_customer_name = '';
            text_campaign_form.value.campaign_search_customer_list = '';
            searchCustomerListBox(type);
        }
        $(document).on('click', '.selected-item-button', function (e) {
            let selected_id = e.target.parentElement.getAttribute('data-id');
            let selected_type = e.target.parentElement.getAttribute('data-type');
            let selected_index = e.target.parentElement.getAttribute('data-index');
            let arrIndex = -1;
            if (selected_type == 'customer') {
                SelectedCustomersList.value.splice(selected_index, 1);
                arrIndex = text_campaign_form.value.campaign_customers.findIndex(thisItem => thisItem == selected_id);
                text_campaign_form.value.campaign_customers.splice(arrIndex, 1);
            } else {
                SelectedCustomersListList.value.splice(selected_index, 1);
                arrIndex = text_campaign_form.value.campaign_customers_list.findIndex(thisItem => thisItem == selected_id);
                text_campaign_form.value.campaign_customers_list.splice(arrIndex, 1);
            }
        });
        $(document).mouseup(function (e) {
            var container = $(".appointment-customers-list-box");
            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if ($('.appointment-customers-list-box').is(':visible')) {
                    container.hide();
                }
            }
        });
        onMounted(() => {
            $(".select2").select2({
                width: '100%',
                placeholder: 'Select',
            });
            $(".email-step1").show();
            $(".email-step2").hide();
            $(".email-step3").hide();
            $(".email-step4").hide();
            $(".email-step5").hide();
            $(".list-tab").hide();
            $(".Individual-tab").hide();
            $(".send-now").hide();
            $(".sche-later").hide();
            $(".timezoon").hide();
            getTextTemplates();
            getCustomersList();
            getCustomers();
            window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            AllTemplates,
            putTemplateValue,
            gotoStep1,
            gotoStep2,
            gotoStep3,
            gotoStep4,
            showRecipients,
            current_Template,
            text_campaign_form,
            AllCustomersLists,
            AllCustomers,
            sendOption,
            handleSubmitCampaign,
            getCompanyDetails,
            company_detail,
            countChar,
            variable_inupt,
            removeClass,
            addClass,
            showToast,

            openCustomerListBox,
            searchCustomerListBox,
            customerSelectId,
            SelectedCustomersList,
            SelectedCustomersListList,

            openTestTextModal,
            testPhoneNo,
            sendTestText,
        };
    },
};
</script>
