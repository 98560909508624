<template>
  <div>
    <!-- loader -->
    <!--        <div class="loaders">-->
    <!--            <div class="loader"></div>-->
    <!--        </div>-->

    <Loader />
    <!-- loader -->

    <!-- invite-Modal -->
    <div class="modal fade" id="send_review_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Send Review Invite</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
            </button>
          </div>
          <div class="modal-body">

            <div class="contact-form">
              <form>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="Customer">
                      <p class="label-txt">Customer Name</p>
                      <input type="text" class=" input" id="Customer">
                    </label>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="CustomerEmial">
                      <p class="label-txt">Customer Phone/Email</p>
                      <input type="email" class=" input" id="CustomerEmial">
                    </label>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="Language">
                      <p class="label-txt">Language</p>
                      <select class="input" id="Language" aria-label="Language">
                        <option></option>
                        <option>English</option>
                        <option>English</option>
                        <option>English</option>
                        <option>English</option>
                      </select>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="submit" class="send-btn" data-toggle="modal" data-target="#exampleModal2"
                data-dismiss="modal"><img class="img-fluid" src="@/assets/img/plane.svg" alt="image title"> Send Review
                Invite
              </button>
              <a class="multi" href="#" data-toggle="modal" data-target="#exampleModal6" data-dismiss="modal">Send
                Multiple</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- invite-Modal -->
    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel2"></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
            </button>
          </div>
          <div class="modal-body">

            <div class="contact-form">
              <div class="after-invite">
                <img class="img-fluid" src="@/assets/img/log.svg" alt="image title">
                <p>Invitation sent 19 munites ago</p>
                <h4>Shahin <span>(+1 801 667 0552)</span></h4>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">
              <button type="submit" class="send-btn" data-toggle="modal" data-target="#exampleModal3"
                data-dismiss="modal">Send Invite Anyway
              </button>
              <a class="multi" href="#">Back</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- invite-Modal -->
    <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel3" aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel3"></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
            </button>
          </div>
          <div class="modal-body">

            <div class="contact-form">
              <div class="after-invite">
                <img class="img-fluid" src="@/assets/img/succ.svg" alt="image title">
                <p class="succ">Review Invite Sent</p>
                <h4>Shahin <span>(+1 801 667 0552)</span></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- locations -->
    <div class="locations-modal">
      <!-- locations-head -->
      <div class="locations-head">
        <div class="modal-header">
          <h5 class="modal-title"> Locations</h5>
          <button type="button" class="close clx">
            <img class="img-fluid" src="@/assets/img/close-w.png" alt="image title">
          </button>
        </div>

        <div class="search-box">
          <form>
            <div class="form-group">
              <input type="search" class="form-control" placeholder="Search" aria-label="search">
              <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
            </div>
          </form>
        </div>
      </div>
      <!-- locations-head -->

      <!-- location-list -->
      <div class="locations-list">
        <ul class="all-list">
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a></li>
          <li><a href="#">last Schulist Ltd <p>2130 George Avenue, Louisville, KY, Kentucky 40261, USA</p></a>
          </li>
        </ul>
      </div>
      <!-- location-list -->

    </div>
    <!-- locations -->





    <!-- invite-Modal -->
    <div class="modal fade" id="exampleModal9" tabindex="-1" aria-labelledby="exampleModalLabel9" aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel9">Send Invite</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
            </button>
          </div>
          <div class="modal-body">

            <div class="contact-form">
              <form>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="customername">
                      <p class="label-txt">Customer Name</p>
                      <input type="text" class=" input" id="customername">
                    </label>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="customerphone">
                      <p class="label-txt">Customer Phone</p>
                      <input type="email" class=" input" id="customerphone">
                    </label>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="customeremial">
                      <p class="label-txt">Customer Email</p>
                      <input type="email" class=" input" id="customeremial">
                    </label>
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="send-btn"><img class="img-fluid" src="@/assets/img/plane.svg"
                      alt="image title"> Send
                    Invite
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- main-area -->
    <section class="main-area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <LeftNav />

            <!-- main-contents -->
            <div class="main-contents">
              <TopHeader :Logout="logout" :RouteObject="Route" />

              <slot />

            </div>
            <!-- main-contents -->

          </div>
        </div>
      </div>
    </section>
    <!-- main-area -->
  </div>
</template>
<style>
@import "https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/font-awesome.min.css";
@import "../assets/css/apexcharts.css";
/*@import "./assets/css/jquery-ui.min.css";*/
@import "../assets/css/daterangepicker.css";
@import "../assets/css/jquery.dataTables.min.css";
@import "../assets/css/responsive.dataTables.min.css";
@import "../assets/css/style.css?v=1.008";
@import "../assets/css/responsive.css";

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
  background-color: #2A4385;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
  background-color: #d33333 !important;
}





.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/*.button-wrapper .label .img-fluid{*/
/*    border-radius: 50%;*/
/*    overflow: hidden;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    object-fit: cover;*/
/*}*/
/*.team-name .img-fluid{*/
/*    border-radius: 50%;*/
/*    overflow: hidden;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    object-fit: cover;*/
/*}*/
</style>
<script>
import $ from "jquery";
import LeftNav from "./../common/LeftNav.vue";
import TopHeader from "./../common/TopHeader.vue";
import "select2";
// import "daterangepicker";
import "datatables.net";
import "datatables.net-responsive";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { onMounted } from "vue";
import Loader from "@/components/Loader";
// import { getToken, messaging, onMessage } from "../firebase";
// import api from "../use/api";
// import { useChatMessagesStore } from "../stores/chatMessages";
import { useAuthUserStore } from "../stores/auth";

// import Pusher from "pusher-js";

export default {
  components: {
    Loader,
    LeftNav,
    TopHeader,
  },
  setup() {
    
    const Router = useRouter()
    const Route = useRoute()
    //Router.push("/inbox");
    // Read if localstorage changed for logged in user session and logout the tabs


    if (!localStorage.getItem("auth_token")) {
      window.location.href = '/?' + new Date().getTime();
    }
    onMounted(() => {


      const authStore = useAuthUserStore();
      authStore.loadUserInfo();
      // Loader Fadeout
      // $(".loaders").fadeOut();
      // Collapse Menu
      $(".menuwidth").click(function () {
        $(".menu-area").toggleClass("menu-full");
        $(".hide-item").toggleClass("show-item");
        $(".small-logo").toggleClass("show-logo");
        $(".main-contents").toggleClass("menu-full-body");
      });
      // select 2
      $(".select2").select2();
      //tabs responsive
      $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
        console.log(e);
        $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
      });
      $(".display :checkbox").change(function () {
        $(this).parent().parent().toggleClass("selecet-bg");
      });
      $(".display :checkbox").change(function () {
        $(this).parent().parent().parent().toggleClass("selecet-bg");
      });
      $(".display :checkbox").change(function () {
        if ($(this).is(":checked")) {
          $(".carpost-area").addClass("show-car");
        } else {
          $(".carpost-area").removeClass("show-car");
        }
      });
      // Select 2 Two Way binding
      $(".select2")
        .select2()
        .on("select2:select", (e) => {
          const event = new Event("change", { bubbles: true, cancelable: true });
          e.params.data.element.parentElement.dispatchEvent(event);
        })
        .on("select2:unselect", (e) => {
          const event = new Event("change", { bubbles: true, cancelable: true });
          e.params.data.element.parentElement.dispatchEvent(event);
        });

    });
    /*----Pusher Message Start----* /
    const pusher = new Pusher('3f202806c5b779461f0b', {
      cluster: 'ap2',
    });
    const pusher_channel_name = "streamly-channel-"+localStorage.getItem('dealer_id');
    const pusher_event = "chat-message-"+Route.params.message_id;
    const pusher_event_open = "chat-message-open";
    const channel = pusher.subscribe(pusher_channel_name);
    channel.bind(pusher_event, (payload) => {
      // Method to be dispatched on trigger.
      console.log(payload);
      const chatStore = useChatMessagesStore();
      chatStore.addNewMessageFromNotification(payload.data.message, payload.data.target_id)
      // console.log('Message received ', this.$route);
    });
    channel.bind(pusher_event_open, (payload) => {
      // Method to be dispatched on trigger.
      console.log(payload);
      // const chatStore = useChatMessagesStore();
      // chatStore.addNewMessageFromNotification(payload.data.message, payload.data.target_id)
      // console.log('Message received ', this.$route);
    });
    const scrollToBottomFunc = async () => {
      $('.chats .modal-body').animate({
        scrollTop: $('.chats .modal-body').get(0).scrollHeight
      }, 50);
    }
    console.log("Channel: "+pusher_channel_name);
    console.log("Event: "+pusher_event);
    console.log("Open Event: "+pusher_event_open);
    /*----Pusher Message End----*/
    return { Route, Router,
      //  scrollToBottomFunc
      }
  },

  created() {

/** /
    // start  function update  token
    const { sendPostRequest } = api();
    getToken(messaging, { vapidKey: 'BE9yPw-x6Na46UsMnP-eF3_23HTBF1K-gBbDKWBiiqQ1QcqSGp2GDiC6cakTAb8_JtMYAKRDYMCwkQp5pghlGno' })
      .then((currentToken) => {


        if (currentToken) {


          localStorage.setItem("FCM-Token", currentToken)

          sendPostRequest('updateFcmToken', {
            'fcm_token': currentToken
          });
          console.log("FCM-Token", currentToken);

        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    /// end function to update token


    onMessage(messaging, (payload) => {


      if (parseInt(payload.data.msgType) === 2) { /// if  chat
        const chatStore = useChatMessagesStore();
        // this.$route.name;

        chatStore.addNewMessageFromNotification(JSON.parse(payload.data.message), payload.data.target_id)
        /// if route message
        // if (this.$route.name === 'Messages') { // if current route is
        //
        // }
        console.log('Message received ', this.$route);


      }
      console.log('Message received ', payload.data.msgType);
      console.log(JSON.parse(payload.data.message));
      console.log('Message received ', payload.data.target_id);
      console.log('Message received.111 ', payload);

      // counter.value++;
      // createToast({ title: payload.notification.title, description: payload.notification.body }, { type: 'info' });

    });
    /// end function to receive  fcm notification
/**/

  },
  methods: {
    logout: async () => {
      // console.log('logout')
      axios
        .post(axios.defaults.baseUrl + "logout")
        .then((response) => {
          if (response) {
            localStorage.clear();
            // localStorage.removeItem("auth_token");
            // localStorage.removeItem("user");
            // localStorage.removeItem("user_id");
            // localStorage.removeItem("dealer");
            // localStorage.removeItem("dealer_id");
            // localStorage.removeItem("user_type");
            // localStorage.removeItem("first_name");
            // localStorage.removeItem("last_name");
            // localStorage.removeItem("permissions");
            window.toast.fire({
              icon: "success",
              title: "User is logout successfully",
            });
            window.location.href = '/login?' + new Date().getTime();
          }
        })
        .catch(() => {
          localStorage.clear();
          // localStorage.removeItem("auth_token");
          // localStorage.removeItem("user");
          // localStorage.removeItem("user_id");
          // localStorage.removeItem("dealer");
          // localStorage.removeItem("dealer_id");
          // localStorage.removeItem("user_type");
          window.location.href = '/login?' + new Date().getTime();
        });
    }
  }

};
</script>
