<template>
<!-- team-area -->
<div class="team-area">
    <!--<div class="team-btns">
      <ul>
        <li><a class="actives crn" href="#">Active</a></li>
        <li><a class="deactive" href="#">Deactive</a></li>
      </ul>
    </div>-->

    <!-- active-tabs -->
    <div class="active-tab">
        <div class="row">
            <!-- team -->
            <div class="col-sm-6 col-md-4 col-xl-3 abcd" v-for="Member in TeamMembers" :key="Member">
                <div class="team">
                    <div class="deactivetitle">
                        <p>Deactive</p>
                    </div>
                    <div class="navbar dropdown">
                        <a class="action dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="img-fluid" src="@/assets/img/more.svg" alt="image title">
                        </a>

                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:" @click="updateTeamMember(Member)">Edit</a>
                            <a class="dropdown-item dact" href="javascript:" @click="deleteTeamMember(Member.id)"> <span class="dacte">Delete</span> <span class="react">Re-active</span> </a>
                        </div>
                    </div>
                    <div class="team-name">
                        <a href="javascript:">
                            <img class="img-fluid" v-if="Member.user_image" :src="base_url + 'team/' + Member.user_image" alt="image title">
                            <img class="img-fluid" v-else src="@/assets/img/team.png" alt="image title">
                        </a>
                        <h4>{{ Member.first_name }} {{ Member.last_name }}</h4>
                        <p>{{ Member.user_type }}</p>
                    </div>
                    <div class="team-con">
                        <p><img class="img-fluid" src="@/assets/img/teamcall.svg" alt="image title"> +{{
                  Member.country_code
                }}{{ Member.phone }}</p>
                        <p><img class="img-fluid" src="@/assets/img/teamemail.svg" alt="image title"> {{ Member.email }}</p>
                        <!--
              <div class="mess-review">
                <label class="checkboxss">Reply Reviews
                  <input type="checkbox" checked="checked">
                  <span class="checkmark"></span>
                </label>
                <label class="checkboxss">Message
                  <input type="checkbox">
                  <span class="checkmark"></span>
                </label>
              </div>
-->
                    </div>
                </div>
            </div>
            <!-- team -->

            <!-- team -->
            <div class="col-sm-6 col-md-4 col-xl-3">
                <div class="add-team">
                    <a class="action-btn" href="javascript:" @click="addTeamMember"><img class="img-fluid" src="@/assets/img/add-team.svg" alt="image title" /></a>
                </div>
            </div>
            <!-- team -->

        </div>
    </div>

    <!-- deactive-tabs -->

    <div class="deactive-tab">

        <div class="row">

            <!--
        <div class="col-sm-6 col-md-4 col-xl-3">
          <div class="team deactives">
            <div class="deactivetitle">
              <p>Deactive</p>
            </div>
            <div class="navbar dropdown">
              <a class="action dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false"><img class="img-fluid" src="@/assets/img/more.svg" alt="image title">
              </a>

              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#exampleModal5">Edit</a>
                <a class="dropdown-item dact" href="#"> <span class="dacte">Deactive</span> <span
                    class="react">Re-active</span> </a>
              </div>
            </div>
            <div class="team-name">
              <a href="#"><img class="img-fluid" src="@/assets/img/team.png" alt="image title"></a>
              <h4>Khanadaker a Jaman</h4>
              <p>Team Leader</p>
            </div>
            <div class="team-con">
              <p><img class="img-fluid" src="@/assets/img/teamcall.svg" alt="image title"> +1 801 928 1032</p>
              <p><img class="img-fluid" src="@/assets/img/teamemail.svg" alt="image title"> jaman.ak@streamly.com</p>

              <div class="mess-review">
                <label class="checkboxss">Reply Reviews
                  <input type="checkbox" checked="checked">
                  <span class="checkmark"></span>
                </label>
                <label class="checkboxss">Message
                  <input type="checkbox">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
-->

        </div>
    </div>
    <!-- deactive-tabs -->
</div>
<!-- add-member-Modal -->
<div class="modal fade" id="addTeamMember" tabindex="-1" aria-labelledby="exampleModalLabel5" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 v-if="editMode" class="modal-title" id="exampleModalLabel5">Update Member</h5>
                <h5 v-else class="modal-title" id="exampleModalLabel5">Add Member</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                </button>
            </div>
            <div class="modal-body">
                <div class="user-img" style="display: none;">
                    <div class="button-wrapper">
                        <span class="label">
                            <img class="img-fluid" v-if="form.user_image" :src="base_url + 'team/' + form.user_image" alt="image title" id="featured-image" />
                            <img class="img-fluid" v-else src="@/assets/img/users.png" alt="image title" id="featured-image" />
                        </span>
                        <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File" form="addEditTeamForm" @change="user_imageInput" ref="file" />
                    </div>
                    <div class="edit-pro">
                        <img class="img-fluid" src="@/assets/img/camera.svg" alt="image title">
                    </div>
                </div>

                <div class="contact-form">
                    <form v-on:submit.prevent="submitForm" id="addEditTeamForm">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="First">
                                    <p class="label-txt label-active">First Name</p>
                                    <input type="text" class="input" id="first_name" v-model="form.first_name" />
                                </label>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="last">
                                    <p class="label-txt label-active">Last Name</p>
                                    <input type="text" class="input" id="last_name" v-model="form.last_name" />
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12" style="display: none;">
                                <label for="Phone">
                                    <p class="label-txt label-active">Phone Number</p>
                                    <vue-mask class="form-control" mask="(000)000-0000" :options="options" id="phone" placeholder="Phone No." v-model="form.phone"></vue-mask>
                                </label>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="Email">
                                    <p class="label-txt label-active">Email Address</p>
                                    <input type="email" class="input" id="email" v-model="form.email" />
                                </label>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="Email">
                                    <p class="label-txt label-active">Password</p>
                                    <input v-if="!editMode" type="password" class="input" id="password" v-model="form.password" />
                                    <input v-if="editMode" type="password" class="input" id="password" placeholder="Leave Empty if Password is not changed" v-model="form.password" />
                                </label>
                            </div>
                            <!--
                <div class="form-group col-md-12">
                  <label for="Position">
                    <p class="label-txt">Position</p>
                    <input type="text" class="input" id="Position">
                  </label>
                </div>
-->
                        </div>

                        <!--
              <div class="assign-area">
                <div class="assgin-top">
                  <p>Access </p>
                  <a href="#" data-toggle="modal" data-target="#exampleModal6" data-dismiss="modal"
                    aria-label="Close">Assign <img class="img-fluid" src="@/assets/img/plus1.svg"
                      alt="image title"></a>
                </div>
                <div class="mess-review">
                  <label class="checkboxss">Reply Reviews
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                  </label>
                  <label class="checkboxss">Message
                    <input type="checkbox">
                    <span class="checkmark"></span>
                  </label>
                  <label class="checkboxss">All
                    <input type="checkbox">
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div class="tagslist">
                  <ul>
                    <li><a href="#">Mehedi hasan <img class="img-fluid" src="@/assets/img/close.svg"
                          alt="image title"></a></li>
                    <li><a href="#">Ali Akbar <img class="img-fluid" src="@/assets/img/close.svg"
                          alt="image title"></a></li>
                    <li><a href="#">Abul Hasnat <img class="img-fluid" src="@/assets/img/close.svg"
                          alt="image title"></a></li>
                  </ul>
                </div>

              </div>
-->

                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-center">
                    <button v-if="editMode" type="button" class="send-btn" @click="handleMTeamMemberUpdateRequest(form.Id)">
                        Update Member
                    </button>
                    <button v-else type="button" class="send-btn" @click="handleMTeamMemberAddRequest">Add Member</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";
import vueMask from 'vue-jquery-mask';

export default {
    components: {
        // BackendUserModel,
        vueMask
    },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        let TeamMembers = ref([]);
        let teamuser_image = '';
        let editMode = ref(false);
        let form = ref({
            Id: 0,
            user_image: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            // phone: ''
        });

        const getTeamMembers = async () => {
            await axios
                .get(axios.defaults.baseUrl + "teams", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    TeamMembers.value = response.data.data.teamMembers;

                })
        }

        const deleteTeamMember = async (Id) => {
            await axios
                .delete(axios.defaults.baseUrl + "teams/" + Id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    getTeamMembers();
                    closePopup();
                    form.value = '';
                    location.reload(); //-----remove later
                })
        }

        const user_imageInput = async (event) => {
            // console.log(event.target.files);
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $('#featured-image').attr('src', e.target.result);
                }
                reader.readAsDataURL(event.target.files[0]);
                teamuser_image = event.target.files[0];
            }

        }
        const addTeamMember = async () => {
            editMode.value = false;
            form.value.Id = 0;
            form.value.user_image = '';
            form.value.first_name = '';
            form.value.last_name = '';
            form.value.email = '';
            form.value.password = '';
            // form.value.phone = '';
            $("#addTeamMember").modal("show");
        }

        const updateTeamMember = async (Member) => {
            editMode.value = true;
            form.value.Id = Member.id;
            form.value.user_image = Member.user_image;
            form.value.first_name = Member.first_name;
            form.value.first_name = Member.first_name;
            form.value.last_name = Member.last_name;
            form.value.email = Member.email;
            form.value.password = Member.password;
            // form.value.phone = Member.phone;
            $("#addTeamMember").modal("show");
        }

        const closePopup = async () => {
            form.value.Id = 0;
            form.value.user_image = '';
            form.value.first_name = '';
            form.value.last_name = '';
            form.value.email = '';
            form.value.password = '';
            // form.value.phone = '';
            $("#addTeamMember").modal("hide");
        }

        const handleMTeamMemberAddRequest = async () => {
            // const addFormData = new FormData();
            // let formData = new FormData();
            // formData.append('fieldName', fieldName);

            // formData.append('fileType', 'video');  
            // addFormData.append(form.value);
            // addFormData.append("file", _file);
            if (form.value.first_name == '' || form.value.last_name == '' || form.value.email == '' || form.value.password == '') {
                showToast('error', 'Please fill all fields');
                return false;
            }
            if (form.value.password.length < 6) {
                showToast('error', 'Password must be atleast 6 characters!');
                return false;
            }
            window.showHideMainLoader(true);
            let addFormData = new FormData()
            addFormData.append('image', teamuser_image);
            addFormData.append('first_name', form.value.first_name);
            addFormData.append('last_name', form.value.last_name);
            addFormData.append('email', form.value.email);
            addFormData.append('password', form.value.password);
            // addFormData.append('phone', form.value.phone);
            // console.log(teamuser_image);
            await axios
                .post(
                    axios.defaults.baseUrl + "teams",
                    addFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
               .then((response) => {
                    showToast(response.data.status, response.data.message);
                    getTeamMembers();
                    closePopup();
                    form.value = '';
                    location.reload(); //-----remove later
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }

        const handleMTeamMemberUpdateRequest = async (Id) => {
            if (form.value.first_name == '' || form.value.last_name == '' || form.value.email == '') {
                showToast('error', 'Please fill all fields!');
                return false;
            }
            if (form.value.password != '' && form.value.password.length < 6) {
                showToast('error', 'Password must be atleast 6 characters!');
                return false;
            }
            let addFormData = new FormData()
            addFormData.append('image', teamuser_image);
            addFormData.append('first_name', form.value.first_name);
            addFormData.append('last_name', form.value.last_name);
            addFormData.append('email', form.value.email);
            if (form.value.password != ''){
              addFormData.append('password', form.value.password);
            }
            // addFormData.append('phone', form.value.phone);
            // console.log(teamuser_image);
            await axios
                .post(
                    axios.defaults.baseUrl + "teams/" + Id,
                    addFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    showToast(response.data.status, response.data.message);
                    getTeamMembers();
                    closePopup();
                    form.value = '';
                    location.reload(); //-----remove later
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }

        onMounted(() => {
            // conosle.log('asd');
            getTeamMembers();
            window.showHideMainLoader(false);

        })
        return {
            base_url,
            TeamMembers,
            addTeamMember,
            updateTeamMember,
            closePopup,
            handleMTeamMemberAddRequest,
            handleMTeamMemberUpdateRequest,
            deleteTeamMember,
            form,
            user_imageInput,
            editMode,
            showToast
        };
    },
};
</script>
