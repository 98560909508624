<template>
  <!--  <router-view />-->
  <component :is="mainComponent">
    <template v-slot>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </template>
  </component>
</template>
<script>
import "bootstrap";
import "popper.js";

import Dashboard from "@/layouts/Dashboard";
import AuthLayout from "@/layouts/AuthLayout";
import AdminDashboard from "@/layouts/AdminDashboard";
import Home from "@/layouts/Home";
import Stars from "@/components/analytics/Stars";

export default {
  components: { Stars },
  mounted() {


  },
  computed: {

    mainComponent: function () {
      let route_name = this.$route.name;
      // console.log(route_name);
      if (!this.$route.name) return "";
      if(['Home', 'Service'].includes(route_name)){
        return Home;
      }else if(route_name == 'AdminDashboard'){
        return AdminDashboard;
      }else{
        return ['Login', 'Register', 'Registerwizard', 'Welcome'].includes(route_name) ? AuthLayout : Dashboard;
      }
      
    }
  }
};
</script>