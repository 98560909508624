<template>
  <div>
    <!-- loader -->
    <Loader/>
    <!-- loader -->

    <!-- login-area -->
    <section class="login-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="form-area">
              <div class="login-form">
                <div class="form-logo">
                  <router-link to="/"><img class="img-fluid" src="@/assets/img/logo.svg" alt="logo"></router-link>
                  <h3>Login to your account</h3>
                </div>
                <form
                    @submit.prevent="login"
                    class="wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                >
                  <label for="email">
                    <p class="label-txt label-active">Email address</p>
                    <svg class="log-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <title>icons/stroke/email</title>
                      <defs>
                        <path
                            d="M19,18 L5,18 C4.449,18 4,17.552 4,17 L4,7.25 L11.4,12.8 C11.578,12.934 11.789,13 12,13 C12.211,13 12.422,12.934 12.6,12.8 L20,7.25 L20,17 C20,17.552 19.551,18 19,18 L19,18 Z M18.333,6 L12,10.75 L5.667,6 L18.333,6 Z M19,4 L5,4 C3.346,4 2,5.346 2,7 L2,17 C2,18.654 3.346,20 5,20 L19,20 C20.654,20 22,18.654 22,17 L22,7 C22,5.346 20.654,4 19,4 L19,4 Z"
                            id="path-1"/>
                      </defs>
                      <g id="icons/stroke/email" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <mask id="mask-2" fill="white">
                          <use xlink:href="#path-1"/>
                        </mask>
                        <use id="🎨-Icon-Сolor" fill="#180660" xlink:href="#path-1"/>
                      </g>
                    </svg>
                    <input
                        type="email"
                        v-model="formData.email"
                        class="input input-active"
                        required
                    />
                  </label>
                  <label for="password">
                    <p class="label-txt label-active">Password</p>
                    <img class="img-fluid log-icon" src="@/assets/img/lock.svg" alt="image title">
                    <input
                        :type="pType"
                        v-model="formData.password"
                        class="input input-active"
                        required
                    />
                    <img
                        class="img-fluid show-eye"
                        src="@/assets/img/eye.svg"
                        alt=""
                        @click="showPass"
                    />
                    <img
                        class="img-fluid hide-eye"
                        src="@/assets/img/eyeclose.svg"
                        alt=""
                        @click="hidePass"
                        style="display: none"
                    />
                  </label>
                  <!--<label class="checkboxss">Remember me
                    <input type="checkbox">
                    <span class="checkmark"></span>
                    <a class="forgot" href="forgot.html">Forgot Password</a>
                  </label>-->
                  <button type="submit" class="login-btns" :disabled="loading">Login
                    <font-awesome-icon icon="fa-solid fa-spinner" spin v-if="loading"/>

                  </button>
                </form>
                <p class="dont-acc">Don't have an account? <router-link to="/register">Sign up</router-link></p>
                <!--                <h6>or</h6>-->
                <div class="other-login">
                  <!--                  <a href="#"><img class="img-fluid" src="@/assets/img/google.svg" alt="image title">Continue with-->
                  <!--                    Google</a>-->
                  <!--                  <a href="#"><img class="img-fluid" src="@/assets/img/fb.svg" alt="image title">Continue with-->
                  <!--                    Facebook</a>-->


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- login-area -->
  </div>
</template>
<script setup>

import apiRequest from "@/use/api";
import {onMounted, reactive, ref, watch} from "vue";
import $ from "jquery";
import {WOW} from "wowjs";
import {useRouter} from 'vue-router';
import {loadInput} from "@/use/loadInputStyle";
import Loader from "@/components/Loader";

const pType = ref('password');
const {loading, results, status, sendPostRequest} = apiRequest();
const formData = reactive({
  email: "",
  password: "",
})
const showPass = () => {
  pType.value = "text";
  $(".hide-eye").show();
  $(".show-eye").hide();
};
const hidePass = () => {
  pType.value = "password";
  $(".hide-eye").hide();
  $(".show-eye").show();
};

function login() {
  sendPostRequest("login", formData)


}


onMounted(() => {
   window.showHideMainLoader(false);

  //   //wow js
  var wow = new WOW({
    boxClass: "wow",
    animateClass: "animated",
    offset: 0,
    mobile: true,
    live: true,
    scrollContainer: null,
    resetAnimation: true,
  });
  wow.init();
  // Login Fields Focus Effect
  loadInput();


  //
  // if (localStorage.getItem("auth_token")) {
  //   router.push("/dashboard");
  // } else {
  //   // router.push("/");
  // }
  if (localStorage.getItem("auth_token")) {
    router.push("/dashboard");
  }
  //  else {
    // router.push("/");
  // }
});

const router = useRouter();

watch(status, (currentStatus) => {

  if (currentStatus) { ///  if  success login

    localStorage.setItem("auth_token", results.value.access_token);
    localStorage.setItem("user_id", results.value.id);
    localStorage.setItem("first_name", results.value.first_name);
    localStorage.setItem("last_name", results.value.last_name);
    localStorage.setItem("user_type", results.value.user_type);
    localStorage.setItem("dealer_id", results.value.dealer_id);
    localStorage.setItem("location_id", results.value.location_id);
    localStorage.setItem("location", results.value.location);
    localStorage.setItem("location_address", results.value.location_address);
    localStorage.setItem("business_name", results.value.business_name);
    // console.log(localStorage);
    if(results.value.user_type == 'superadmin'){
    router.push("/admin/dashboard");
    }else{
    router.push("/inbox/all");
    }
    // // window.location.href = '/dashboard';

  }

})
</script>

