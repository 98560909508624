import { createWebHistory, createRouter } from "vue-router"
import Home from "@/views/FrontPages/Home.vue"
import Service from "@/views/FrontPages/Services.vue"
import Login from "@/layouts/Login.vue"
import Register from "@/layouts/Register.vue"
import Registerwizard from "@/layouts/Registerwizard.vue"
import Dashboard from "@/views/Dashboard/Dashboard.vue"
import Dashboard2 from "@/views/Dashboard/Dashboard2.vue"
import Leaderboard from "@/views/Dashboard/Leaderboard.vue"
import Team from "@/views/Team/Team.vue"
import Appointment from "@/views/Appointment/Appointment.vue"
import Task from "@/views/Task/Task.vue"
import Customer from "@/views/Customer/Customer.vue"
import CustomerList from "@/views/CustomerList/CustomerList.vue"
import Locations from "@/views/Locations/Locations.vue"
import Alert from "@/views/Alert/Alert.vue"
// import Inbox from "@/views/Messages/Inbox.vue"
import Inbox2 from "@/views/Messages/Inbox2.vue"
import Report from "@/views/Messages/Report.vue"
import Reviews from "@/views/Review/Reviews.vue"
import Analytics from "@/views/Review/Analytics.vue"
import Invites from "@/views/Review/Invites.vue"
import Performance from "@/views/Review/Performance.vue"
import ReviewSettings from "@/views/Review/Settings.vue"
import Virtual from "@/views/Meeting/Virtual.vue"
import Inperson from "@/views/Meeting/Inperson.vue"
import Emailcampaign from "@/views/EmailMarketing/Emailcampaign.vue"
import Addemailcampaign from "@/views/EmailMarketing/Addemailcampaign.vue"
import Emailtemplates from "@/views/EmailMarketing/Emailtemplates.vue"
import Textcampaign from "@/views/TextMarketing/Textcampaign.vue"
import Textcampaigndetail from "@/views/TextMarketing/Textcampaigndetail.vue"
import Emailcampaigndetail from "@/views/EmailMarketing/Emailcampaigndetail.vue"
import Addtextcampaign from "@/views/TextMarketing/Addtextcampaign.vue"
import Texttemplates from "@/views/TextMarketing/Texttemplates.vue"
import Calltracking from "@/views/Tracking/Call.vue"
import Smstracking from "@/views/Tracking/Sms.vue"
import Callsettings from "@/views/Tracking/Settings.vue"
import Settings from "@/views/Settings/Settings.vue"

// import GoogleMyBusiness from "@/views/GoogleMyBusiness/GoogleMyBusiness.vue"

import AdminDashboard from "@/views/Admin/Dashboard/Dashboard.vue"
import AdminCustomers from "@/views/Admin/Customer/Customer.vue"
import AdminClients from "@/views/Admin/Client/Client.vue"
import AdminClientsFilter from "@/views/Admin/Client/Client.vue"
import AdminClientsAdd from "@/views/Admin/Client/AddClient.vue"
import AdminCampaigns from "@/views/Admin/Campaign/Campaign.vue"
import AdminMessages from "@/views/Admin/Message/Message.vue"
import AdminClientsBuyPhoneNo from "@/views/Admin/Client/ClientBuyPhoneNo.vue"
import AdminTags from "@/views/Admin/Tag/Tag.vue"
import AdminTagsAdd from "@/views/Admin/Tag/AddTag.vue"



// Admin Routes
let routes = [];
  routes = [
    // {
    //   path: "/dashboard/google-my-business",
    //   name: "mybusiness",
    //   component: GoogleMyBusiness
    // },
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/services/:service",
      name: "Service",
      component: Service
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/register/wizard",
      name: "Registerwizard",
      component: Registerwizard
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        pageTitle: "Dashboard",
        breadCrumbs: [
        {
          name: 'Dashboard',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/dashboard2",
      name: "Dashboard2",
      component: Dashboard2,
      meta: {
        pageTitle: "Dashboard2",
        breadCrumbs: [
        {
          name: 'Dashboard2',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/leaderboard",
      name: "Leaderboard",
      component: Leaderboard,
      meta: {
        pageTitle: "Leaderboard",
        breadCrumbs: [
        {
          name: 'Leaderboard',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/team",
      name: "Team",
      component: Team,
      meta: {
        pageTitle: "Team",
        breadCrumbs: [
        {
          name: 'Team',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/appointments",
      name: "Appointments",
      component: Appointment,
      meta: {
        pageTitle: "Appointments",
        breadCrumbs: [
        {
          name: 'Appointments',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/tasks",
      name: "Tasks",
      component: Task,
      meta: {
        pageTitle: "Tasks",
        breadCrumbs: [
        {
          name: 'Tasks',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/customers/lists",
      name: "CustomersList",
      component: CustomerList,
      meta: {
        pageTitle: "Customers Lists",
        breadCrumbs: [
        {
          name: 'CustomersList',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/customers",
      name: "Customers",
      component: Customer,
      meta: {
        pageTitle: "Customers",
        breadCrumbs: [
        {
          name: 'Customers',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/customers/:id/:new?",
      name: "CustomersWithList",
      component: Customer,
      params: true,
      meta: {
        pageTitle: "Customers",
        breadCrumbs: [
        {
          name: 'CustomersWithList',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/locations",
      name: "Locations",
      component: Locations,
      meta: {
        pageTitle: "Locations",
        breadCrumbs: [
        {
          name: 'Locations',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/alerts",
      name: "Alerts",
      component: Alert,
      meta: {
        pageTitle: "Alerts",
        breadCrumbs: [
        {
          name: 'Alerts',
          url: '',
          class: 'active'
        }]
      }
    },
    // {
    //   path: "/inbox/:message_id?",
    //   name: "Messages2",
    //   component: Inbox,
    //   meta: {
    //     pageTitle: "Messages",
    //     breadCrumbs: [
    //       {
    //         name: 'Messages',
    //         url: '',
    //         class: 'active'
    //       }]
    //   }
    // },
    {
      path: "/inbox/:type?/:chat_id?",
      name: "Messages",
      component: Inbox2,
      meta: {
        pageTitle: "Messages",
        breadCrumbs: [
          {
            name: 'Messages',
            url: '',
            class: 'active'
          }]
      }
    },
    {
      path: "/report",
      name: "Report",
      component: Report,
      meta: {
        pageTitle: "Report",
        breadCrumbs: [
        {
          name: 'Report',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/reviews",
      name: "Reviews",
      component: Reviews,
      meta: {
        pageTitle: "Reviews",
        breadCrumbs: [
        {
          name: 'Reviews',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/analytics",
      name: "Analytics",
      component: Analytics,
      meta: {
        pageTitle: "Analytics",
        breadCrumbs: [
        {
          name: 'Analytics',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/invites",
      name: "Invites",
      component: Invites,
      meta: {
        pageTitle: "Invites",
        breadCrumbs: [
        {
          name: 'Invites',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/performance",
      name: "Performance",
      component: Performance,
      meta: {
        pageTitle: "Performance",
        breadCrumbs: [
        {
          name: 'Performance',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/review-settings",
      name: "ReviewSettings",
      component: ReviewSettings,
      meta: {
        pageTitle: "Review Settings",
        breadCrumbs: [
        {
          name: 'ReviewSettings',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/virtual",
      name: "Virtual",
      component: Virtual,
      meta: {
        pageTitle: "Virtual",
        breadCrumbs: [
        {
          name: 'Virtual',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/inperson",
      name: "Inperson",
      component: Inperson,
      meta: {
        pageTitle: "Inperson",
        breadCrumbs: [
        {
          name: 'Inperson',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/text-templates",
      name: "TextTemplates",
      component: Texttemplates,
      meta: {
        pageTitle: "TextTemplates",
        breadCrumbs: [
        {
          name: 'TextTemplates',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/email-campaign",
      name: "EmailCampaign",
      component: Emailcampaign,
      meta: {
        pageTitle: "EmailCampaign",
        breadCrumbs: [
        {
          name: 'EmailCampaign',
          url: '',
          class: 'active'
        }]
      }
    },
    // {
    //   path: "/email-campaign/:id",
    //   name: "EmaialCampaignDetail",
    //   component: Emailcampaigndetail,
    //   meta: {
    //     pageTitle: "EmailCampaignDetail",
    //     breadCrumbs: [
    //     {
    //       name: 'EmailCampaignDetail',
    //       url: '',
    //       class: 'active'
    //     }]
    //   }
    // },
    {
      path: "/email-campaign/add",
      name: "AddEmailCampaign",
      component: Addemailcampaign,
      meta: {
        pageTitle: "AddEmailCampaign",
        breadCrumbs: [
        {
          name: 'AddEmailCampaign',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/email-templates",
      name: "EmailTemplates",
      component: Emailtemplates,
      meta: {
        pageTitle: "EmailTemplates",
        breadCrumbs: [
        {
          name: 'EmailTemplates',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/text-campaign",
      name: "TextCampaign",
      component: Textcampaign,
      meta: {
        pageTitle: "TextCampaign",
        breadCrumbs: [
        {
          name: 'TextCampaign',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/text-campaign/:id",
      name: "TextCampaignDetail",
      component: Textcampaigndetail,
      meta: {
        pageTitle: "TextCampaignDetail",
        breadCrumbs: [
        {
          name: 'TextCampaignDetail',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/email-campaign/:id",
      name: "EmailCampaignDetail",
      component: Emailcampaigndetail,
      meta: {
        pageTitle: "EmailCampaignDetail",
        breadCrumbs: [
        {
          name: 'EmailCampaignDetail',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/text-campaign/add",
      name: "AddTextCampaign",
      component: Addtextcampaign,
      meta: {
        pageTitle: "AddTextCampaign",
        breadCrumbs: [
        {
          name: 'AddTextCampaign',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/call-tracking",
      name: "Calltracking",
      component: Calltracking,
      meta: {
        pageTitle: "Calltracking",
        breadCrumbs: [
        {
          name: 'Calltracking',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/sms-tracking",
      name: "Smstracking",
      component: Smstracking,
      meta: {
        pageTitle: "Smstracking",
        breadCrumbs: [
        {
          name: 'Smstracking',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/call-settings",
      name: "Callsettings",
      component: Callsettings,
      meta: {
        pageTitle: "Callsettings",
        breadCrumbs: [
        {
          name: 'Callsettings',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: {
        pageTitle: "Settings",
        breadCrumbs: [
        {
          name: 'Settings',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/dashboard",
      name: "AdminDashboard",
      component: AdminDashboard,
      meta: {
        pageTitle: "Admin Dashboard",
        breadCrumbs: [
        {
          name: 'Admin Dashboard',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/customers",
      name: "AdminCustomers",
      component: AdminCustomers,
      meta: {
        pageTitle: "Admin Customers",
        breadCrumbs: [
        {
          name: 'Admin Customers',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/clients",
      name: "AdminClients",
      component: AdminClients,
      meta: {
        pageTitle: "Admin Clients",
        breadCrumbs: [
        {
          name: 'Admin Clients',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/clients/:param",
      name: "AdminClientsFilter",
      component: AdminClientsFilter,
      meta: {
        pageTitle: "Admin Clients with Filter",
        breadCrumbs: [
        {
          name: 'Admin Clients with Filter',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/clients/add",
      name: "AdminClientsAdd",
      component: AdminClientsAdd,
      meta: {
        pageTitle: "Admin Clients Add",
        breadCrumbs: [
        {
          name: 'Admin Clients Add',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/campaigns",
      name: "AdminCampaigns",
      component: AdminCampaigns,
      meta: {
        pageTitle: "Admin Campaigns",
        breadCrumbs: [
        {
          name: 'Admin Campaigns',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/messages",
      name: "AdminMessages",
      component: AdminMessages,
      meta: {
        pageTitle: "Admin Messages",
        breadCrumbs: [
        {
          name: 'Admin Messages',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/clients/:id/buy_phone_no",
      name: "AdminClientsBuyPhoneNo",
      component: AdminClientsBuyPhoneNo,
      meta: {
        pageTitle: "Admin Clients Buy Phone No",
        breadCrumbs: [
        {
          name: 'Admin Clients Buy Phone No',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/tags",
      name: "AdminTags",
      component: AdminTags,
      meta: {
        pageTitle: "Admin Tags",
        breadCrumbs: [
        {
          name: 'Admin Tags',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: "/admin/tags/add",
      name: "AdminTagsAdd",
      component: AdminTagsAdd,
      meta: {
        pageTitle: "Admin Tags Add",
        breadCrumbs: [
        {
          name: 'Admin Tags Add',
          url: '',
          class: 'active'
        }]
      }
    },
    {
      path: '/:pathMatch(.*)*',
      component: Login
    }
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router